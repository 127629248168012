import React, { forwardRef } from "react";
import get from "lodash.get";

import InputMask from "react-input-mask";
import { Controller } from "react-hook-form";

interface FormInputProps {
  register: Function;
  errors: Object[];
  control: any;
}
interface InputProps {
  form: FormInputProps;
  validation?: object;
  name: string;
  type: string;
  mask?: string;
  placeholder?: string;
  label?: string;
  id?: string;
  required?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  onBlur?: any;
  className?: string;
  max?: number;
  min?: number;
  value?: any;
  onChange?: any;
  wrapperClass?: string;
  style?: any;
  labelStyle?: any;
  checked?: boolean;
}

const Input = ({
  form,
  validation,
  label,
  name,
  mask,
  placeholder,
  id,
  type,
  required,
  disabled,
  onBlur,
  className,
  wrapperClass,
  style,
  checked,
  labelStyle,
  ...inputProps
}: InputProps): JSX.Element => {
  const { register, errors, control }: FormInputProps = form;
  const hasError = get(errors, name);
  const inputCommonProps = {
    type,
    name,
    id,
    placeholder,
    ref: register(validation),
    className: `${
      className ?? ""
    } w-full bg-gray-100 border p-4 h-14 rounded-md ${
      disabled && "opacity-50"
    } focus:border-gray-200 focus:bg-white focus:outline-none ${
      typeof hasError !== "undefined"
        ? "border-primary-border"
        : "border-secondary-border"
    }`,
    disabled,
    style,
    ...inputProps,
  };

  return (
    <section className={wrapperClass}>
      {label && (
        <label
          htmlFor={id || name}
          className="block text-base mb-3"
          style={labelStyle}
        >
          {label} {required && <span className="text-orange">*</span>}
        </label>
      )}
      {mask ? (
        <Controller
          mask={mask}
          as={InputMask}
          rules={validation}
          name={name}
          control={control}
          defaultValue={inputCommonProps.defaultValue || ""}
          className={inputCommonProps.className}
        />
      ) : (
        <input
          {...inputCommonProps}
          onBlur={(event) => (onBlur ? onBlur(event?.target.value) : {})}
          defaultValue={inputCommonProps.defaultValue || ""}
          checked={checked}
        />
      )}
    </section>
  );
};

export default forwardRef(Input);

