import React from 'react'
import { useTranslation } from 'react-i18next'
import { Header, Footer } from '../../@components/layout'
import { useCheckout } from '../../@hooks'

export const Pending = (): JSX.Element => {
  const { t } = useTranslation()
  const { plan } = useCheckout()

  return (
    <>
      <Header />
      <section className="w-full px-6 sm:px-6">
        <section className="bg-white sm:w-144.5 w-full mx-auto">
          <section className="w-full p-6 rounded-3xl my-12 shadow-lg">
            <section className="bg-primary bg-opacity-5 border border-primary border-opacity-40 w-full flex flex-col items-center p-14 rounded-3xl">
              <section className="w-36 h-36 bg-white text-orange border border-primary border-opacity-40 rounded-full p-6">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              </section>
            </section>

            <h1 className="text-4xl font-bold mt-12 mb-6 text-center sm:text-left">
              {t('pending.title')}
            </h1>

            <p className="text-base mb-6 text-center sm:text-left" dangerouslySetInnerHTML={{ __html: t('pending.paragraph.first', { plan: plan.name }) }} />
          </section>
        </section>
      </section>
      <Footer />
    </>
  )
}
