import React, { Suspense } from "react";
import { useHistory } from "react-router";
import { Loader } from "./@components/layout";
import { Api } from "./@services/api";
import { ApiContext, PaymentOptionsProps } from "./@contexts";
import {
  Checkout as CheckoutProvider,
  Company as CompanyProvider,
} from "./@providers";
import Routes from "./routes";

const App = (): JSX.Element => {
  const history = useHistory();
  const basePath = history.location.pathname.split("/")[1];

  return (
    <Suspense fallback={<Loader />}>
      <ApiContext.Provider value={new Api()}>
        <CompanyProvider basePath={basePath}>
          <CheckoutProvider
            step={1}
            employees={1}
            plan={{
              name: "",
              amount: 0,
              recurrence: 0,
              external_reference: "",
              payment_methods: [] as PaymentOptionsProps[],
            }}
            user={{
              name: "",
              cnpj: "",
              company_name: "",
              password: "",
              document_number: "",
              birthdate: "",
              phone: "",
              email: "",
            }}
            address={{
              zipcode: "",
              address: "",
              number: "",
              complement: "",
              district: "",
              city: "",
              state: "",
            }}
            transaction={{
              id: "",
              subscription_id: "",
              subscription_status: "",
              tem_card: "",
              manager: null,
            }}
          >
            <Routes base={basePath} />
          </CheckoutProvider>
        </CompanyProvider>
      </ApiContext.Provider>
    </Suspense>
  );
};

export default App;
