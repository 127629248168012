import React from "react";
import { useTranslation } from "react-i18next";
import { FormatAmount, FormatRecurrence } from "../../../@helpers/format";
import { Plan as PlanProps } from "../../../@contexts";
import { useCheckout } from "../../../@hooks";

interface Props {
  business: boolean;
  plan: PlanProps;
}

const Plan = ({ business, plan }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { amount, name, recurrence, adesion_amount }: PlanProps = plan;
  const { employees } = useCheckout();
  const multipler = business ? employees : 1;
  return (
    <section className="bg-primary bg-opacity-5 border border-primary-border border-opacity-40 w-full flex flex-col p-6 rounded-3xl">
      <section className="w-full flex justify-between items-center flex-col mb-2 sm:flex-row">
        <h3 className="font-bold text-xl my-2 flex justify-center items-center">
          TEM Saúde {name}
        </h3>
        <h3 className="font-bold text-xl my-2 justify-center flex">
          <div className="flex flex-col">
            R$ {FormatAmount(amount * multipler)} /{" "}
            {FormatRecurrence(recurrence)}
            {adesion_amount && (
              <>
                <br />
                <small>
                  + adesão de R$ {FormatAmount(adesion_amount * multipler)}
                </small>
              </>
            )}
          </div>
        </h3>
      </section>
      <section className="flex justify-between">
        <p className="text-base">{t("checkout.header.box.help")}</p>
        {business && <p className="text-base">Por colaborador</p>}
      </section>
    </section>
  );
};

export default Plan;
