import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// Screens
import { Checkout } from "./@containers/Checkout";
import { Success } from "./@containers/Success";
import { Pending } from "./@containers/Pending";
import NotFound from "./@containers/NotFound";

interface RoutesProps {
  base: string;
}

const Routes = ({ base }: RoutesProps): JSX.Element => {
  return (
    <BrowserRouter basename={`/${base === '404' ? '' : base}`}>
      <Switch>
        <Route
          exact
          path="/"
          component={() =>
            base ? <Redirect to={{ pathname: "/basic/user" }} /> : <NotFound />
          }
        />
        <Route
          path="/not-found"
          component={(props: any): JSX.Element => <NotFound {...props} />}
        />
        <Route
          path="/:plan/:step/success"
          component={(props: any): JSX.Element => <Success {...props} />}
        />
        <Route
          path="/pending"
          component={(props: any): JSX.Element => <Pending {...props} />}
        />
        <Route
          exact
          path="/:plan"
          component={(props: any) => {
            const route = props.match.params.plan;
            return (
              <Redirect
                to={{
                  pathname: ["success", "pending"].includes(route)
                    ? `/${route}`
                    : `/${props.match.params.plan}/user`,
                }}
              />
            );
          }}
        />
        <Route
          exact
          path="/:plan/:step"
          component={(props: any): JSX.Element => (
            <Checkout basePath={base} {...props} />
          )}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
