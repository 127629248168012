import React from 'react'
import styled from 'styled-components'

const Container = styled.section`
	background-color: #ffffff;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 1;

	display: flex;
	align-items: center;
	justify-content: center;
`

const Spinner = styled.svg`
	animation: rotate 2s linear infinite;
	margin: -25px 0 0 -25px;
	width: 50px;
	height: 50px;

	& .path {
		stroke: currentColor;
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes dash {
		0% {
			stroke-dasharray: 1, 150;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -35;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -124;
		}
	}
`

const Loader = (): JSX.Element => (
	<Container>
		<Spinner className="text-primary" viewBox="0 0 50 50">
			<circle
				className="path"
				cx="25"
				cy="25"
				r="20"
				fill="none"
				strokeWidth="2"
			/>
		</Spinner>
	</Container>
)

export default Loader
