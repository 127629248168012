import React from "react";
import styled from "styled-components";
import { useCompany } from "../../@hooks";

const Logo = styled.img`
  max-width: 200px;
  max-height: 60px;
`;

const Header = (): JSX.Element => {
  const { company } = useCompany()

  return (
    <header className="bg-white w-full h-20 shadow-primary mb-12">
      <section className="flex items-center justify-center h-full">
        <Logo
          src={company?.operator?.logo.includes('http') ? company?.operator?.logo : `/images/${company?.operator?.logo}`}
        />
      </section>
    </header>
  );
};

export default Header;
