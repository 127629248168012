import axios from "axios";
import { Partner } from "../@contexts";

export class Api {
  api: any;
  api2: any;
  apiAC: any;

  constructor() {
    this.api = axios.create({ baseURL: `${process.env.REACT_APP_API}/api/v1` });
    this.api2 = axios.create({
      baseURL: `${process.env.REACT_APP_API}/api/v2`,
    });
    this.apiAC = axios.create({
      baseURL: `${process.env.REACT_APP_API_ACTIVE_CAMPAIGN}/api/3`,
      headers: {
        'Api-Token': process.env.REACT_APP_API_ACTIVE_CAMPAIGN_KEY
      }
    });
  }

  createBusiness = async (payload: any) => {
    const payloadCompanies = {
      name: payload.company.company_name,
      cnpj: payload.company.cnpj,
      email: payload.email,
      acquired_spaces: parseInt(payload.company.employees),
      password: payload.company.password,
      manager_attributes: (({ company, ...object }) => object)(payload),
    };

    const { data } = await this.api.post("/companies", payloadCompanies);
    return data;
  };

  createCustomer = async (payload: any) => {
    const payloadCustomer = (({ company, ...object }) => object)(payload);
    const { data } = await this.api.post(`/customers`, payloadCustomer);
    return data;
  };

  getPartnerData = async (slug: any): Promise<Partner> => {
    const { data } = await this.api.get(`/admin/partners/${slug}`, {
      headers: {},
    });

    return data;
  };

  getAffiliate = async (slug: string) => {
    const { data } = await this.api.get(`/affiliates/${slug}`);
    return data;
  };

  createCustomerInfo = async (payload: any) => {
    const { data } = await this.api2.post(`/customer_infos`, {
      customer: payload,
    });
    return data;
  };

  createContactTag = async (contact: string | number, tag: string | number) => {
    const { data } = await this.api2.post('/tracking/tag', {
      contactTag: {
        contact,
        tag
      },
    });

    return data;
  }

  createContact = async (payload: any) => {
    const { tag, ...restPayload } = payload;

    const { data } = await this.api2.post('/tracking/contact', {
      contact: restPayload,
    });
    
    const { data: { contactTag } } = await this.createContactTag(data.contact.id, tag)

    return {
      contact: data?.contact ?? {},
      tag: contactTag.tag
    };
  }
}

