import React from 'react'

interface AlertProps {
	children: string
}

const Alert = ({ children }: AlertProps): JSX.Element => (
	<section className="bg-primary bg-opacity-5 w-full flex flex-col items-center py-4 px-6 rounded">
    <p className="text-base text-orange">{children}</p>
  </section>
)

export default Alert
