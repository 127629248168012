import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ReactGA from "react-ga";
import { useApi, useCheckout, useCompany } from "../../../@hooks";
import { Controller } from "react-hook-form";
import axios from "axios";
import { unMask } from "../../../@helpers/unmask";
import { States } from "../../../@utils/enums";
import ReCAPTCHA from "react-google-recaptcha";
import { Alert, Notification } from "../../../@components/layout";
import { Form, Input, Select } from "../../../@components/form";
import Step from "./Step";
import errorFormater from "../../../@helpers/errorFormater";
import Tags from "../../../@utils/enums/tags";

interface AddressFormProps {
  form?: any;
  loading: boolean;
  onValidate?: any;
}

interface CepProps {
  data: {
    bairro: string;
    cep: string;
    complemento: string;
    ddd: string;
    gia: string;
    ibge: string;
    localidade: string;
    logradouro: string;
    siafi: string;
    uf: string;
  };
}

const Address = ({ form, loading, onValidate }: AddressFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { control, watch, formState } = form;
  const [searching, setSearching] = useState(true);
  const [fieldsInvalid, setFieldsInvalid] = useState(false);

  const zipcode = watch("zipcode");

  const setFormValues = ({ data }: CepProps) => {
    form.setValue("address", data.logradouro);
    form.setValue("district", data.bairro);
    form.setValue("city", data.localidade);
    form.setValue("state", data.uf);
  };

  const onSearchCEP = async () => {
    try {
      const cep = unMask(form.getValues("zipcode"), /-/i);
      const response: any = await axios.get(`//viacep.com.br/ws/${cep}/json`);
      setFieldsInvalid(false);
      if (response.data.erro) {
        setFieldsInvalid(true);
        form.setError("zipcode", { type: "validate" });
      } else {
        setFormValues(response);
      }
      setSearching(false);
    } catch (error) {
      setSearching(false);
      setFieldsInvalid(true);
      form.setError("zipcode", { type: "validate" });
    }
  };

  useEffect(() => {
    if (zipcode && unMask(zipcode, /-|_/g).length === 8) {
      onSearchCEP();
    } // eslint-disable-next-line
  }, [zipcode]);

  const isZipCodeValid = (number: string): boolean => {
    const unmaskedValue = unMask(number, /-/g);
    const uniqueValues = Array.from(new Set(unmaskedValue.split("")));

    if (unmaskedValue.length === 8 && uniqueValues.length > 1) return true;
    return false;
  };

  useEffect(() => {
    onValidate(formState.isValid);
  }, [formState.isValid, onValidate]);

  return (
    <section className="grid grid-cols-12 gap-6">
      <section className="col-span-12 sm:col-span-12">
        <Input
          validation={{
            required: true,
            validate: isZipCodeValid,
          }}
          name="zipcode"
          form={form}
          disabled={loading}
          label={t("checkout.form.address.fields.zipcode")}
          type="tel"
          placeholder={t("checkout.form.address.placeholders.type")}
          mask="99999-999"
          required
        />
      </section>
      <section className="col-span-12 sm:col-span-8">
        <Controller
          control={control}
          name="address"
          rules={{ required: true }}
          render={(props) => (
            <Input
              {...props}
              form={form}
              disabled={loading || searching || fieldsInvalid}
              label={t("checkout.form.address.fields.address")}
              type="text"
              placeholder={t("checkout.form.address.placeholders.type")}
              required
            />
          )}
        />
      </section>
      <section className="col-span-12 sm:col-span-4">
        <Controller
          control={control}
          name="number"
          rules={{ required: true }}
          render={(props) => (
            <Input
              {...props}
              form={form}
              disabled={loading || searching || fieldsInvalid}
              label={t("checkout.form.address.fields.number")}
              type="text"
              placeholder={t("checkout.form.address.placeholders.type")}
              required
            />
          )}
        />
      </section>
      <section className="col-span-12 sm:col-span-6">
        <Controller
          control={control}
          name="complement"
          render={(props) => (
            <Input
              {...props}
              form={form}
              disabled={loading || searching || fieldsInvalid}
              label={t("checkout.form.address.fields.complement")}
              type="text"
              placeholder={t("checkout.form.address.placeholders.type")}
            />
          )}
        />
      </section>
      <section className="col-span-12 sm:col-span-6">
        <Controller
          control={control}
          name="district"
          rules={{ required: true }}
          render={(props) => (
            <Input
              {...props}
              form={form}
              disabled={loading || searching || fieldsInvalid}
              label={t("checkout.form.address.fields.district")}
              type="text"
              placeholder={t("checkout.form.address.placeholders.type")}
              required
            />
          )}
        />
      </section>
      <section className="col-span-12 sm:col-span-6">
        <Controller
          control={control}
          name="city"
          rules={{ required: true }}
          render={(props) => (
            <Input
              {...props}
              form={form}
              disabled={loading || searching || fieldsInvalid}
              label={t("checkout.form.address.fields.city")}
              type="text"
              placeholder={t("checkout.form.address.placeholders.type")}
              required
            />
          )}
        />
      </section>
      <section className="col-span-12 sm:col-span-6">
        <Controller
          control={control}
          name="state"
          rules={{ required: true }}
          render={(props) => (
            <Select
              {...props}
              form={form}
              options={States.map((state: string) => ({
                label: state,
                value: state,
              }))}
              disabled={loading || searching || fieldsInvalid}
              label={t("checkout.form.address.fields.state")}
              placeholder={t("checkout.form.address.placeholders.select")}
              required
            />
          )}
        />
      </section>
      {(!loading || searching) &&
        form.errors &&
        Object.keys(form.errors).length > 0 && (
          <section className="mt-8 col-span-12 sm:col-span-12">
            <Alert>
              {form.errors.zipcode && form.errors.zipcode.type === "validate"
                ? t("validations.fields.validate.zipcode")
                : t("validations.fields.required")}
            </Alert>
          </section>
        )}
    </section>
  );
};

interface AddressProps {
  basePath: string;
  affiliate: any;
}

interface AddressDataProps {
  zipcode: string;
  address: string;
  number: string;
  complement?: string;
  district: string;
  city: string;
  state: string;
}

const FormAddress = ({ basePath, affiliate }: AddressProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setAddress, setStep, setUser, user, plan } = useCheckout();
  const { createCustomerInfo, createContactTag } = useApi();
  const { company } = useCompany();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | null>(null);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<null | string>(null);

  const isDisabled = useMemo(() => loading || !buttonEnabled || !formValid, [buttonEnabled, formValid, loading]);

  const trackContact = useCallback(async () => {
    try {
      await createContactTag(user?.contact ?? null, Tags.userAddress.id);
    } catch (err) {
      console.error(err);
    }
  }, [createContactTag, user]);

  const track = (): void => {
    const pathName = `/${basePath + history.location.pathname}${
      history.location.search ? history.location.search : ""
    }`;
    ReactGA.set({ page: pathName });
    ReactGA.pageview(pathName);
    console.log("page view", pathName);
  };

  const handleSubmit = async (address: AddressDataProps) => {
    setError(null);
    const birthdate = user.birthdate.split("/");
    const customerInfoPayload = {
      onix: plan.onix,
      full_name: user.name,
      document_number: unMask(user.document_number, /\.|-/g),
      document_type: company?.operator?.business ? 1 : 0,
      birthdate: `${birthdate[2]}-${birthdate[1]}-${birthdate[0]}`,
      phone_number: unMask(user.phone, /\(|\)|-| /g), // eslint-disable-next-line
      zip_code: unMask(address.zipcode, /\-/i), // eslint-disable-next-line
      email: user.email,
      address: address.address,
      number: address.number,
      complement: address.complement,
      neighborhood: address.district,
      city: address.city,
      state: address.state,
      token: recaptchaToken,
    };
    setLoading(true);
    createCustomerInfo(customerInfoPayload)
      .then((result: any) => {
        setUser({ ...user, customer_info_id: result.id });
        setStep(3);
        setAddress(address);
        company?.operator?.slug === 'tem' && trackContact();
        track();
        history.push(`terms${affiliate ? `?affiliate=${affiliate.slug}` : ""}`);
      })
      .catch((error) => {
        console.log(error?.response, "ERRO");
        setLoading(false);
        setError(error);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const onChange = (value: null | string) => {
    setButtonEnabled(value !== null);
    setRecaptchaToken(value);
  };

  const recaptchaRef = useRef<ReCAPTCHA>();

  return (
    <section className="w-full p-6 rounded-3xl my-12 shadow-lg">
      <Step />
      <section className="pt-12 border-t border-gray-100">
        <Form onSubmit={handleSubmit}>
          <Address loading={loading} onValidate={setFormValid} />
          <div className="flex my-4 justify-center" id="recaptchaForm">
            <ReCAPTCHA
              ref={recaptchaRef as React.LegacyRef<ReCAPTCHA>}
              size="compact"
              sitekey={process.env.REACT_APP_GOOGLE_KEY as string}
              onChange={onChange}
            />
          </div>
          <section className="mt-6">
            <button
              id="button-checkout-address"
              type="submit"
              className={`w-full h-11 px-6 bg-primary text-white text-base font-bold rounded-full hover:bg-opacity-70 focus:outline-none ${isDisabled && 'disabled:opacity-70 cursor-not-allowed'}`}
              disabled={isDisabled}
            >
              {t(`buttons.${loading ? "loading" : "confirm"}`)}
            </button>
          </section>
        </Form>
      </section>

      <Notification
        open={error !== null}
        onClose={() => setError(null)}
        title="Falha ao registrar cliente"
        message={errorFormater(error)}
        variant="error"
      />
    </section>
  );
};

export default FormAddress;
