import * as React from "react";
import { Header } from "../../@components/layout";

function NotFound() {
  return (
    <>
      <Header />
      <section className="mb-10">
        <img src="/images/404.svg" width="460px" className="flex mx-auto" alt="Página não encontrada" />
      </section>
      <section className="text-center w-full px-6 sm:px-6 space-y-5">
        <h1 className="text-3xl font-bold">
          Não encontramos a página que você tentou acessar.
        </h1>
        <p>Verifique o link ou digite corretamente a página.</p>
      </section>
      <section className="w-full text-center mt-10">
        <a href="https://www.temsaude.com/para-voce" className="w-60 h-16 mx-auto rounded-full border text-primary border-primary flex items-center justify-center">
          Site da Tem Saúde
        </a>
      </section>
    </>
  );
}

export default NotFound;
