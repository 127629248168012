import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ReactGA from "react-ga";
import { useCheckout, useApi, useCompany } from "../../../@hooks";
import { Controller } from "react-hook-form";
import { unMask } from "../../../@helpers/unmask";
import { Alert, Loading, Notification } from "../../../@components/layout";
import { Form, Input, Select } from "../../../@components/form";
import Step from "./Step";
import { Plan } from "../../../@contexts";
import ReCAPTCHA from "react-google-recaptcha";
import errorFormater from "../../../@helpers/errorFormater";
import FormBillingInfo from "./FormBillingInfo";
import { formatDateToISO } from "../../../@helpers/format";
import FormBoleto from "./FormBoleto";
import FormPix from "./FormPix";
import Tags from "../../../@utils/enums/tags";

interface PaymentFormProps {
  form?: any;
  loading: boolean;
  business: boolean;
  plan: Plan;
  onValidate?: any;
}

const FormCard = ({
  form,
  loading,
  business,
  onValidate,
}: Omit<PaymentFormProps, "plan">): JSX.Element => {
  const { t } = useTranslation();
  const { employees, setEmployees } = useCheckout();
  const { company } = useCompany();
  const { control, formState } = form;

  const isValidCard = (number: string) => {
    const cardNumber = number.replace(/\s/g, "");
    const binString = company?.bin?.values.map(String);

    const isBinValid =
      company?.bin?.values && company?.bin?.values?.length > 0
        ? binString?.some((bin) =>
            bin.split("").every((val, index) => {
              return cardNumber[index] === val;
            })
          )
        : true;

    return isBinValid;
  };

  const isDateValid = (number: string) => {
    const [month, year] = number.split("/");
    const currentYear = +new Date().getFullYear().toString().slice(2);
    const currentMonth = +new Date().getMonth() + 1;
    const isValidYear = year !== "00" && +year >= currentYear;
    let isValidMonth = +month >= 1 && +month <= 12;
    if (+month <= currentMonth && +year === currentYear) {
      isValidMonth = false;
    }
    if (number.match(/^\d{2}\/\d{2}$/g) && isValidYear && isValidMonth)
      return true;
    if (unMask(number, /\/|_/g).length === 4 && isValidYear && isValidMonth)
      return true;
    return false;
  };

  const isCodeValid = (number: string) => {
    if (unMask(number, /_/g).length === 3) return true;
    return false;
  };

  useEffect(() => {
    onValidate(formState.isValid);
  }, [formState.isValid, onValidate]);

  return (
    <>
      {business && (
        <section className="col-span-12 sm:col-span-12">
          <Controller
            control={control}
            name="employees"
            rules={{ required: true }}
            render={(props) => (
              <Input
                {...props}
                form={form}
                // disabled
                label={t("checkout.form.payment.fields.employees")}
                placeholder={t("checkout.form.payment.placeholders.type")}
                type="number"
                value={employees}
                onChange={(e: any) => {
                  setEmployees(parseInt(e.target.value) || 1);
                }}
                required
              />
            )}
          />
        </section>
      )}
      <section className="col-span-12 sm:col-span-12">
        <Controller
          control={control}
          name="card_holder"
          rules={{ required: true }}
          render={(props) => (
            <Input
              {...props}
              form={form}
              disabled={loading}
              label={t("checkout.form.payment.fields.cardholder")}
              placeholder={t("checkout.form.payment.placeholders.type")}
              type="text"
              required
            />
          )}
        />
      </section>
      <section className="col-span-12 sm:col-span-12">
        <Controller
          control={control}
          name="card_number"
          rules={{
            required: true,
            validate: isValidCard,
          }}
          render={(props) => (
            <Input
              {...props}
              form={form}
              disabled={loading}
              label={t("checkout.form.payment.fields.number")}
              placeholder={t("checkout.form.payment.placeholders.type")}
              type="tel"
              mask="9999 9999 9999 9999"
              required
            />
          )}
        />
      </section>
      <section className="col-span-12 sm:col-span-6">
        <Input
          name="card_validate"
          validation={{
            required: true,
            validate: isDateValid,
          }}
          form={form}
          disabled={loading}
          label={t("checkout.form.payment.fields.validate")}
          placeholder={t("checkout.form.payment.placeholders.type")}
          type="tel"
          mask="99/99"
          required
        />
      </section>
      <section className="col-span-12 sm:col-span-6">
        <Controller
          control={control}
          name="card_cvv"
          rules={{
            required: true,
            validate: isCodeValid,
          }}
          render={(props) => (
            <Input
              {...props}
              form={form}
              disabled={loading}
              label={t("checkout.form.payment.fields.cvv")}
              placeholder={t("checkout.form.payment.placeholders.type")}
              type="tel"
              mask="999"
              required
            />
          )}
        />
      </section>
    </>
  );
};

const FormCode = ({
  form,
  loading,
}: Omit<PaymentFormProps, "plan">): JSX.Element => {
  const { t } = useTranslation();
  const { control } = form;

  return (
    <>
      <section className="col-span-12 sm:col-span-12">
        <Controller
          control={control}
          name="code_number"
          rules={{ required: true }}
          render={(props) => (
            <Input
              {...props}
              form={form}
              disabled={loading}
              label="Número do código promocional"
              placeholder={t("checkout.form.payment.placeholders.type")}
              type="text"
              required
            />
          )}
        />
      </section>
    </>
  );
};

const Payment = ({
  form,
  loading,
  business,
  plan,
  onValidate,
}: PaymentFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { control } = form;
  const [paymentMethod, setPaymentMethod] = useState("");
  const { company } = useCompany();
  const renderForm = () => {
    const forms = [FormCard, FormCode, FormBoleto, FormPix];
    const types = ["credit_card", "code", "boleto", "pix"];
    const Component = forms[types.indexOf(paymentMethod)];
    return (
      <Component
        business={business}
        form={form}
        loading={loading}
        plan={plan}
        onValidate={onValidate}
      />
    );
  };

  const showFormErrors = () => {
    if (
      form.errors?.card_number &&
      form.errors?.card_number.type === "validate"
    )
      return t("validations.fields.validate.invalid_bin", {
        card: company?.bin?.type,
      });
    if (
      form.errors?.card_validate &&
      form.errors?.card_validate.type === "validate"
    )
      return t("validations.fields.validate.card_validate");
    if (form.errors?.card_cvv && form.errors.card_cvv?.type === "validate")
      return t("validations.fields.validate.card_cvv");
    return t("validations.fields.required");
  };

  return (
    <section className="grid grid-cols-12 gap-6">
      <section className="col-span-12 sm:col-span-12">
        <Controller
          control={control}
          name="payment_method"
          rules={{ required: true }}
          render={(props) => (
            <Select
              {...props}
              form={form}
              options={plan.payment_methods}
              disabled={loading}
              label={t("checkout.form.payment.fields.payment_method")}
              placeholder={t("checkout.form.payment.placeholders.select")}
              onChange={(value: string) => setPaymentMethod(value)}
              required
            />
          )}
        />
      </section>
      {paymentMethod !== "" && (
        <FormBillingInfo form={form} loading={loading} />
      )}
      {paymentMethod !== "" && renderForm()}

      <section className="mt-6 col-span-12 sm:col-span-12">
        <p
          dangerouslySetInnerHTML={{
            __html: t("checkout.form.payment.placeholders.accept"),
          }}
        />
      </section>
      {!loading && form.errors && Object.keys(form.errors).length > 0 && (
        <section className="mt-8 col-span-12 sm:col-span-12">
          <Alert>{showFormErrors()}</Alert>
        </section>
      )}
    </section>
  );
};

interface PaymentProps {
  basePath: string;
  plan: Plan;
  affiliate?: any;
}

interface PaymentDataProps {
  payment_method: string;
  card_holder: string;
  card_number: string;
  card_validate: string;
  card_cvv: string;
}

const FormPayment = ({
  plan,
  basePath,
  affiliate,
}: PaymentProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { company } = useCompany();
  const { user, address, setTransaction, employees } = useCheckout();
  const { createCustomer, createBusiness, createContactTag } = useApi();
  const [error, setError] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<null | string>(null);

  const isDisabled = useMemo(() => !buttonEnabled || (buttonEnabled && loading) || !formValid, [buttonEnabled, formValid, loading]);

  const trackContact = useCallback(async () => {
    try {
      await createContactTag(user?.contact ?? null, Tags.userPayment.id);
    } catch (err) {
      console.error(err);
    }
  }, [createContactTag, user]);

  const track = (): void => {
    const pathName = `/${basePath + history.location.pathname}${
      history.location.search ? history.location.search : ""
    }`;
    ReactGA.set({ page: pathName });
    ReactGA.pageview(pathName);
    console.log("page view", pathName);
  };

  const handleSubmit = async (data: any) => {
    setError(null);
    setLoading(true);

    const companyData = {
      company_name: user.company_name,
      cnpj: user.cnpj && unMask(user.cnpj, /\D/g),
      email: user.email,
      employees,
      password: user.password,
    };

    const method = company?.operator?.business
      ? createBusiness
      : createCustomer;
    try {
      // let billing_document = company?.operator?.business
      //   ? unMask(companyData.cnpj,/\D/g)
      //   : data.use_info_as_billing
      //   ? unMask(user.document_number,/\D/g)
      //   : unMask(data.billing_info.document_number,/\D/g);
      let billing_document = !data.use_info_as_billing
        ? unMask(user.document_number, /\D/g)
        : unMask(data.billing_info.document_number, /\D/g);
      const payload: any = {
        company: company?.operator?.business ? companyData : null,
        brand: company?.operator?.slug,
        onix: plan.onix,
        full_name: user.name,
        document_number: unMask(user.document_number, /\D/g), // eslint-disable-next-line
        birthdate: formatDateToISO(user.birthdate),
        phone_number: unMask(user.phone, /\(|\)|-| /g), // eslint-disable-next-line
        zip_code: unMask(address.zipcode, /\-/i), // eslint-disable-next-line
        email: user.email,
        address: address.address,
        number: address.number,
        complement: address.complement,
        neighborhood: address.district,
        city: address.city,
        state: address.state,
        payment_form: data.payment_method,
        affiliate_slug: affiliate?.slug ?? null,
        customer_info_id: user.customer_info_id,
        holder_name: data.card_holder,
        token: recaptchaToken,
        holder_document: billing_document,
        holder_document_type: company?.operator?.business ? "CNPJ" : "CPF",
        payment_period: data.payment_method !== 'credit_card' ? parseInt(data.payment_period) : null,
        billing_info: {
          full_name: data.card_holder,
          document_number: billing_document,
          document_type: !data.use_info_as_billing
            ? company?.operator?.business
              ? "CNPJ"
              : "CPF"
            : data.billing_info.document_type,
        },
      };
      if (payload.payment_form === "credit_card") {
        const card_validate = data.card_validate.split("/");
        payload.card_number = unMask(data.card_number, / /g);
        payload.security_code = data.card_cvv;
        payload.expiration_month = card_validate[0];
        payload.expiration_year = card_validate[1];
      }

      const response: any = await method(payload);

      setLoading(false);
      setError(false);
      setTransaction({
        id: response.id,
        subscription_id: response.subscription_id ?? "",
        subscription_status: response.subscription_status ?? "",
        tem_card: response.tem_card ?? "",
        manager: response.manager ?? null,
        payment_form: payload.payment_form,
        boleto_url: response?.boleto_url ?? "",
      });
      if (company?.operator?.slug === 'tem') {
        await trackContact();
      }
      track();
      history.push(
        `/${company?.operator.slug}/${plan.external_reference}/success${
          affiliate ? `?affiliate=${affiliate.slug}` : ""
        }`
      );
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(error);
    }
  };

  const onChange = (value: null | string) => {
    setButtonEnabled(value !== null);
    setRecaptchaToken(value);
  };

  const recaptchaRef = useRef<ReCAPTCHA>();

  return (
    <>
      <section className="w-full p-6 rounded-3xl my-12 shadow-lg">
        <Step />
        <section className="pt-12 border-t border-gray-100">
          <Form
            onSubmit={(data: PaymentDataProps) => handleSubmit(data)}
            defaultValues={{ payment_period: '6' }}
          >
            <Payment
              plan={plan}
              business={company?.operator?.business as boolean}
              loading={loading}
              onValidate={setFormValid}
            />
            <div className="flex my-4 justify-center" id="recaptchaForm">
              <ReCAPTCHA
                ref={recaptchaRef as React.LegacyRef<ReCAPTCHA>}
                size="compact"
                sitekey={process.env.REACT_APP_GOOGLE_KEY as string}
                onChange={onChange}
              />
            </div>
            <section className="mt-6">
              <button
                id="button-checkout-payment"
                type="submit"
                className={`w-full h-11 px-6 bg-orange disabled:cursor-not-allowed disabled:opacity-50 text-white text-base font-bold rounded-full hover:bg-opacity-70 focus:outline-none`}
                disabled={isDisabled}
              >
                {loading ? <Loading /> : t("buttons.accept_subscription")}
              </button>
            </section>
          </Form>
        </section>
      </section>

      <Notification
        open={error !== null}
        onClose={() => setError(null)}
        title="Falha ao processar pagamento"
        message={errorFormater(error)}
        variant="error"
      />
    </>
  );
};

export default FormPayment;
