import React, { useEffect, useState } from "react";
import { Loader } from "../@components/layout";
// import NotFound from "../@containers/NotFound/index";
import { Partner, CompanyContext, Company } from "../@contexts/index";
import ReactGA from "react-ga";
import { useApi } from "../@hooks";
import i18n from "../i18n";
interface ProviderProps {
  children: JSX.Element;
  basePath: string | null;
}

let root = document.documentElement;

const loadDynamicScript = (position: string, script: any) => {
  const scriptNode = document
    .createRange()
    .createContextualFragment(script.trim());

  if (position === "head") document.head.appendChild(scriptNode);
  if (position === "body") document.body.appendChild(scriptNode);
};

export const CompanyProvider = ({
  children,
  basePath,
}: ProviderProps): JSX.Element => {
  const { getPartnerData } = useApi();
  const [company, setCompany] = useState<Company | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const loadCompanyConfig = async (company: string) => {
    try {
      const data: Partner = await getPartnerData(company);

      if (!data) {
        setLoading(false);

        return false;
      }

      root.style.setProperty(
        "--primary",
        data.configurations.operator.theme.primary
      );
      root.style.setProperty(
        "--secondary",
        data.configurations.operator.theme.secondary
      );
      i18n.addResourceBundle(
        "pt-BR",
        "translation",
        data.configurations.translation
      );

      if (data?.configurations?.google_analytics) {
        ReactGA.initialize(data.configurations.google_analytics);
        console.log("Google Analytics Init");
      }

      if (data?.configurations?.script_head)
        loadDynamicScript("head", data.configurations.script_head);
      if (data?.configurations?.script_body)
        loadDynamicScript("body", data.configurations.script_body);

      setCompany(data.configurations);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (basePath) {
      loadCompanyConfig(basePath);
    }

    if (!basePath) {
      setLoading(false);
    } // eslint-disable-next-line
  }, [basePath]);

  if (isLoading) {
    return <Loader />;
  }

  // if (!isLoading && !company) {
  //   return <NotFound />;
  // }

  return (
    <CompanyContext.Provider
      value={{
        company,
        setCompany,
        isLoading,
        loadCompanyConfig,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export default CompanyProvider;
