import React, { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import { Header, Footer } from "../../@components/layout";
import { useCheckout, useCompany } from "../../@hooks";
import { useHistory } from "react-router";

const RenderSuccess = ({ transaction, company, t, plan }: any) => {
  if (
    (transaction.boleto_url !== "" ||
      transaction?.manager?.boleto_url !== "") &&
    (transaction.payment_form === "boleto" ||
      transaction?.manager?.payment_form === "boleto")
  )
    return (
      <>
        <p>Falta pouco para você começar a cuidar da sua saúde!</p>
        <p>
          Que bom que você tomou a decisão de começar a cuidar do que mais
          importa: sua saúde.
        </p>
        <p>
          Enviamos no seu email o boleto para pagamento, caso prefira clique e
          realize o pagamento para ter acesso ao seu Cartão Tem Saúde:
        </p>
        <p className="text-center mt-2 mb-2">
          <a
            href={transaction.boleto_url || transaction?.manager?.boleto_url}
            style={{
              color: "#bc3915",
              textDecoration: "underline",
              fontWeight: "bold",
              fontSize: 24,
            }}
            target="_blank"
            rel="noreferrer"
          >
            Visualizar Boleto
          </a>
        </p>

        <p>
          <b>Próximos passos:</b>
        </p>
        <p>
          O boleto pode demorar até 3 dias para ser compensado, assim que
          recebermos a confirmação de pagamento você vai receber o e-mail de
          Boas Vindas e SMS com tudo que precisa para começar a se cuidar.
        </p>
      </>
    );
  if (
    (transaction.boleto_url !== "" ||
      transaction?.manager?.boleto_url !== "") &&
    (transaction.payment_form === "pix" ||
      transaction?.manager?.payment_form === "pix")
  )
    return (
      <>
        <p>Falta pouco para você começar a cuidar da sua saúde!</p>
        <p>
          Que bom que você tomou a decisão de começar a cuidar do que mais
          importa: sua saúde.
        </p>
        <p style={{ marginBottom: 16 }}>
          Enviamos no seu email o qr-code para pagamento do PIX e caso prefira
          aponte a camera do seu celular para o qr code abaixo e realize o
          pagamento para ter acesso ao seu Cartão Tem Saúde:
        </p>
        <img
          src={transaction.boleto_url || transaction?.manager?.boleto_url}
          style={{ margin: "auto" }}
          alt="pix"
        />
        <p className="text-center mt-2 mb-2">
          <a
            href={transaction.boleto_url}
            style={{
              color: "#bc3915",
              textDecoration: "underline",
              fontWeight: "bold",
              fontSize: 14,
            }}
            target="_blank"
            rel="noreferrer"
          >
            Visualizar Qr Code PIX em uma nova aba
          </a>
        </p>
      </>
    );
  if (transaction.manager !== null)
    return (
      <>
        <p
          className="text-base mb-6 text-center sm:text-left"
          dangerouslySetInnerHTML={{
            __html: t("business.paragraph.first"),
          }}
        />
        <p className="text-base mb-6 text-center sm:text-left">
          {t("business.paragraph.middle")}
        </p>
        <p className="text-base mb-12 text-center sm:text-left">
          {t("business.paragraph.last")}
        </p>
        <a
          href={company?.painel_login}
          target="_blank"
          rel="noreferrer"
          className="w-full h-11 flex justify-center items-center px-6 bg-primary text-white text-base font-bold rounded-full hover:bg-opacity-70 focus:outline-none"
        >
          Painel Administrativo
        </a>
      </>
    );
  if (transaction.tem_card !== "")
    return (
      <>
        <p
          className="text-base mb-6 text-center sm:text-left"
          dangerouslySetInnerHTML={{
            __html: t("success.paragraph.first", { plan: plan?.name }),
          }}
        />
        <p className="text-base mb-12 text-center sm:text-left">
          {t("success.paragraph.last")}
        </p>
        <a
          href={company?.painel_login}
          target="_blank"
          rel="noreferrer"
          className="w-full h-11 flex justify-center items-center px-6 bg-primary text-white text-base font-bold rounded-full hover:bg-opacity-70 focus:outline-none"
        >
          Visitar Área Logada
        </a>
      </>
    );
  return (
    <>
      <p
        className="text-base mb-6 text-center sm:text-left"
        dangerouslySetInnerHTML={{
          __html: t("success.paragraph.pending", { plan: plan.name }),
        }}
      />
    </>
  );
};

export const Success = (): JSX.Element => {
  const { t } = useTranslation();
  const { transaction, plan } = useCheckout();
  const { company } = useCompany();
  const history = useHistory();
  const track = useCallback(() => {
    const pathName = `${history.location.pathname}${
      history.location.search ? history.location.search : ""
    }`;
    ReactGA.set({ page: pathName });
    ReactGA.pageview(pathName);
    console.log("page view", pathName);
  }, [history]);

  useEffect(() => {
    track();
  }, [track]);

  return (
    <>
      <Header />
      <section className="w-full px-6 sm:px-6">
        <section className="bg-white sm:w-144.5 w-full mx-auto">
          <section className="w-full p-6 rounded-3xl my-12 shadow-lg">
            <section className="bg-primary bg-opacity-5 border border-primary border-opacity-40 w-full flex flex-col items-center p-14 rounded-3xl">
              <section className="w-36 h-36 bg-white text-primary border border-primary border-opacity-40 rounded-full p-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </section>
            </section>

            <h1 className="text-4xl font-bold mt-12 mb-6 text-center sm:text-left">
              {t("success.title")}
            </h1>
            <RenderSuccess
              transaction={transaction}
              t={t}
              company={company}
              plan={plan}
            />
          </section>
        </section>
      </section>
      <Footer />
    </>
  );
};

