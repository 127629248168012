import React, { forwardRef } from "react";
import get from "lodash.get";

interface FormInputProps {
  register: Function;
  errors: Object[];
}

interface OptionsProps {
  label: string;
  value: string;
}

interface InputProps {
  form: FormInputProps;
  options: OptionsProps[];
  validation?: object;
  name: string;
  mask?: string;
  placeholder?: string;
  label?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: any;
  selectedValue?: any;
}

const Select = ({
  form,
  options,
  validation,
  label,
  name,
  placeholder,
  id,
  required,
  disabled,
  onChange,
  selectedValue,
  ...inputProps
}: InputProps): JSX.Element => {
  const { register, errors }: FormInputProps = form;
  const hasError = get(errors, name);
  const inputCommonProps = {
    name,
    id,
    placeholder,
    ref: register(validation),
    className: `w-full bg-gray-100 border p-4 h-14 rounded-md ${
      disabled && "opacity-50"
    } focus:border-gray-200 focus:bg-white focus:outline-none ${
      typeof hasError !== "undefined" && "border-red"
    }`,
    disabled,
    ...inputProps,
  };

  return (
    <section>
      {label && (
        <label htmlFor={id || name} className="block text-base mb-3">
          {label} {required && <span className="text-red">*</span>}
        </label>
      )}
      <select
        {...inputCommonProps}
        onChange={(event) => (onChange ? onChange(event?.target.value) : {})}
      >
        <option value="">{placeholder}</option>
        {options.map((option: OptionsProps) => (
          <option
            key={`option_${option.value}`}
            value={option.value}
            selected={option.value === selectedValue}
          >
            {option.label}
          </option>
        ))}
      </select>
    </section>
  );
};

export default forwardRef(Select);

