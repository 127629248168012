import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./style.css";
import "./i18n";
import { Router } from "react-router-dom";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const Render = (): JSX.Element => {
  useEffect(() => {
    return history.listen((location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
      console.log("page view", location.pathname);
    });
  }, []);

  return (
    <Router history={history}>
      <App />
    </Router>
  );
};

ReactDOM.render(<Render />, document.getElementById("root") as HTMLElement);

