import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ReactGA from "react-ga";
import { useApi, useCheckout, useCompany } from "../../../@hooks";
import Step from "./Step";
import Tags from "../../../@utils/enums/tags";

interface TermsProps {
  basePath: string;
  affiliate: any;
}

const FormTerms = ({ basePath, affiliate }: TermsProps): JSX.Element => {
  const { t } = useTranslation();
  const { company } = useCompany();
  const { user, setStep } = useCheckout();
  const { createContactTag } = useApi();
  const history = useHistory();

  const trackContact = useCallback(async () => {
    try {
      await createContactTag(user?.contact ?? null, Tags.userTerms.id);
    } catch (err) {
      console.error(err);
    }
  }, [createContactTag, user]);

  const track = (): void => {
    const pathName = `/${basePath + history.location.pathname}${
      history.location.search ? history.location.search : ""
    }`;
    ReactGA.set({ page: pathName });
    ReactGA.pageview(pathName);
    console.log("page view", pathName);
  };

  return (
    <section className="w-full p-6 rounded-3xl my-12 shadow-lg">
      <Step />
      <section className="pt-12 border-t border-gray-100">
        <section className="bg-gray-100 w-full h-60 border rounded-md overflow-auto mb-6 pt-6 px-6">
          <section
            className="mb-6 terms"
            dangerouslySetInnerHTML={{ __html: company?.terms || "" }}
          ></section>
        </section>
        <p
          dangerouslySetInnerHTML={{
            __html: t("checkout.form.terms.placeholders.accept", {
              termo_link: company?.termo || "",
            }),
          }}
        />
        <section className="mt-12">
          <button
            id="button-checkout-terms"
            type="button"
            className="w-full h-11 px-6 bg-primary text-white text-base font-bold rounded-full hover:bg-opacity-70 focus:outline-none"
            onClick={() => {
              setStep(4);
              company?.operator?.slug === 'tem' && trackContact();
              track();
              history.push(
                `payment${affiliate ? `?affiliate=${affiliate.slug}` : ""}`
              );
            }}
          >
            {t("buttons.accept_terms")}
          </button>
        </section>
      </section>
    </section>
  );
};

export default FormTerms;
