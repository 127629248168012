import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Input, Select } from "../../../@components/form";

const FormBillingInfo = ({
  form,
  loading,
}: {
  form: any;
  loading: boolean;
}) => {
  const [useInfoAsBilling, setUseInfoAsBilling] = useState(false);
  const [defaultDocumentType, setDefaultDocumentType] = useState("CPF");

  const { control } = form;
  const isDocumentNumberValid = (number: string) => {
    number = number.replace(/\D/g, "");

    if (number.toString().length !== 11 || /^(\d)\1{10}$/.test(number))
      return false;

    let result = true;

    [9, 10].forEach((j) => {
      let sumCpf = 0,
        rest;

      number
        .split(/(?=)/)
        .splice(0, j)
        .forEach((digit, i) => {
          sumCpf += parseInt(digit) * (j + 2 - (i + 1));
        });

      rest = sumCpf % 11;
      rest = rest < 2 ? 0 : 11 - rest;

      if (rest.toString() !== number.substring(j, j + 1)) result = false;
    });

    return result;
  };

  const isCNPJValid = (value: any) => {
    const match = value.toString().match(/\d/g);
    const uniqueValues = Array.from(new Set(match));
    const numbers = Array.isArray(match) ? match.map(Number) : [];

    if (uniqueValues.length === 1) return false;
    if (numbers.length !== 14) return false;

    const items = [...match];
    if (items.length === 1) return false;

    const calc = (x: number) => {
      const slice = numbers.slice(0, x);
      let factor = x - 7;
      let sum = 0;

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor--;
        if (factor < 2) factor = 9;
      }

      const result = 11 - (sum % 11);

      return result > 9 ? 0 : result;
    };

    const digits = numbers.slice(12);

    const digit0 = calc(12);
    if (digit0 !== digits[0]) return false;

    const digit1 = calc(13);
    return digit1 === digits[1];
  };

  return (
    <>
      <section className="col-span-12 sm:col-span-12">
        <Controller
          control={control}
          name="use_info_as_billing"
          render={(props) => (
            <Input
              {...props}
              form={form}
              disabled={loading}
              label="Os dados de cobrança/titular de cartão são diferentes?"
              type="checkbox"
              wrapperClass="flex w-full"
              style={{ width: "auto", marginLeft: 16, height: "auto" }}
              labelStyle={{ margin: 0 }}
              required
              checked={useInfoAsBilling}
              onChange={(input: any) =>
                setUseInfoAsBilling(input.target.checked)
              }
            />
          )}
        />
      </section>
      {useInfoAsBilling && (
        <>
          <section className="col-span-12 sm:col-span-6">
            <Select
              name="billing_info.document_type"
              form={form}
              options={[
                { label: "CPF", value: "CPF" },
                { label: "CNPJ", value: "CNPJ" },
              ]}
              label="Tipo de Documento"
              required
              selectedValue={defaultDocumentType}
              onChange={(value: any) => setDefaultDocumentType(value)}
            />
          </section>
          <section className="col-span-12 sm:col-span-6">
            <Input
              name="billing_info.document_number"
              validation={{
                required: true,
                validate:
                  defaultDocumentType === "CPF"
                    ? isDocumentNumberValid
                    : isCNPJValid,
              }}
              form={form}
              disabled={loading}
              label={`${defaultDocumentType} de Cobrança`}
              type="tel"
              placeholder=""
              mask={
                defaultDocumentType === "CPF"
                  ? "999.999.999-99"
                  : "99.999.999/9999-99"
              }
              required
            />
          </section>
        </>
      )}
    </>
  );
};

export default FormBillingInfo;

