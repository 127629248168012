import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Select } from "../../../@components/form";
import { Plan } from "../../../@contexts";
import { FormatAmount } from "../../../@helpers/format";
import { useCheckout } from "../../../@hooks";

interface PaymentFormProps {
  form?: any;
  loading: boolean;
  business: boolean;
  plan: Plan;
  onValidate?: any;
}

export const FormBoleto = ({
  form,
  loading,
  business,
  plan,
  onValidate,
}: PaymentFormProps): JSX.Element => {
  const [paymentPeriod, setPaymentPeriod] = useState("6");
  const { employees } = useCheckout();
  const { control, formState } = form;
  const total_amount =
  (plan.adesion_amount || 0) + plan.amount * parseInt(paymentPeriod);

  useEffect(() => {
    onValidate(formState.isValid);
  }, [formState.isValid, onValidate]);

  return (
    <>
      <section className="col-span-12 sm:col-span-12">
        <Controller
          control={control}
          name="payment_period"
          rules={{ required: true }}
          render={({ value, name, onChange}) => (
            <Select
              name="payment_period"
              form={form}
              selectedValue={paymentPeriod}
              options={[
                { label: "6", value: "6" },
                { label: "12", value: "12" },
              ]}
              disabled={loading}
              label={"Período contratado (6 ou 12 meses)"}
              placeholder="Selecione um valor"
              onChange={(value: string) => setPaymentPeriod(value)}
              required
            />
          )}
        />
      </section>
      <section className="col-span-12 sm:col-span-12">
        <p>
          <b>Valor total</b>
        </p>
        <p>
          ( Adesão de R$ {FormatAmount(plan.adesion_amount || 0)} + (
          {paymentPeriod}x de R$ {FormatAmount(plan.amount)})) = R${" "}
          {FormatAmount(total_amount)}
          {business ? ` por colaborador ` : ""}
        </p>
        <p>
          {business && employees > 1
            ? ` Valor para ${employees} colaboradores = R$ ${FormatAmount(
                total_amount * employees
              )}`
            : ""}
        </p>
      </section>
    </>
  );
};

export default FormBoleto;

