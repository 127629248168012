import React from "react";

type FooterProps = {
  affiliate?: any;
  onChange?: () => void;
  onRemove?: () => void;
};

const Footer = ({
  affiliate,
  onChange,
  onRemove,
}: FooterProps): JSX.Element => {
  return (
    <>
      {affiliate?.slug && (
        <section className="w-full px-6 flex items-center justify-center mb-12 sm:px-6">
          <section className="sm:w-144.5 w-full mx-auto bg-white shadow-lg rounded-3xl p-8">
            <section className="flex items-center justify-between">
              <p>
                Código do Afiliado: <strong>{affiliate.slug}</strong>
              </p>
              {/* <section className="flex items-center space-x-4">
                <button onClick={onChange} className="text-base underline">
                  Alterar
                </button>
                <button onClick={onRemove} className="text-primary text-base underline">
                  Remover
                </button>
              </section> */}
            </section>
          </section>
        </section>
      )}
      <footer className="bg-gray-100 w-full h-20 py-8">
        <p className="text-base text-center">
          © {`${new Date().getFullYear()} TEM Saúde`}
        </p>
      </footer>
    </>
  );
};

export default Footer;

