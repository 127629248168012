import { useTranslation } from "react-i18next";

export const FormatAmount = (value: number) =>
  (value / 100).toLocaleString("pt-BR", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

export const FormatRecurrence = (value: number) => {
  const { t } = useTranslation();
  const periods = [t("pricing.recurrence.month"), t("pricing.recurrence.year")];
  const recurrence = [30, 360];
  return periods[recurrence.indexOf(Number(value))];
};

export const formatDateToISO = (date: string): string => {
  const birthdate = date.split("/");
  return `${birthdate[2]}-${birthdate[1]}-${birthdate[0]}`;
};
