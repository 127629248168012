import { createContext } from "react";

export interface Partner {
  configurations: Company;
  slug: string;
}
export interface Company {
  operator: Operator;
  plans: Plan[];
  painel_login: string;
  google_analytics: string;
  public?: boolean;
  bin?: Bin;
  terms?: string;
  termo: string;
  translation?: any;
  plan_url?: string;
  script_head?: string;
  script_body?: string;
}

export interface Bin {
  type: string;
  values: string[];
}
export interface Operator {
  name: string;
  slug: string;
  logo: string;
  theme: Theme;
  business?: boolean;
}

export interface Theme {
  primary: string;
  secondary: string;
}

export interface PaymentOptionsProps {
  label: string;
  value: string;
}

export interface Plan {
  name: string;
  amount: number;
  adesion_amount?: number;
  payment_methods: PaymentOptionsProps[];
  recurrence: number;
  external_reference: string;
  onix?: string;
}

export interface User {
  name: string;
  cnpj: string;
  company_name: string;
  password: string;
  document_number: string;
  birthdate: string;
  phone: string;
  email: string;
  customer_info_id?: number;
  contact?: string | number | null;
}
export interface CompanyContextProps {
  company: Company | null;
  isLoading: boolean;
  setCompany: (company: Company) => void;
  loadCompanyConfig: (company: string) => void;
}

export const CompanyContext = createContext<CompanyContextProps>({
  company: null,
  setCompany() {},
  loadCompanyConfig() {},
  isLoading: false,
});

export const ApiContext = createContext({
  createBusiness: async (payload: any, business?: boolean) => ({}),
  createCustomer: async (payload: any, business?: boolean) => ({}),
  getPartnerData: async (slug: string) => ({} as Partner),
  getAffiliate: async (slug: string) => ({}),
  createCustomerInfo: async (payload: any) => ({}),
  createContactTag: async (contactId: any, tagId: any) => ({}),
  createContact: async (payload: any) => ({})
});

export const CheckoutContext = createContext({
  step: 1,
  employees: 0,
  plan: {} as Plan,
  user: {
    name: "",
    cnpj: "",
    company_name: "",
    password: "",
    document_number: "",
    birthdate: "",
    phone: "",
    email: "",
    customer_info_id: undefined,
    contact: null
  } as User,
  address: {
    zipcode: "",
    address: "",
    number: "",
    complement: "",
    district: "",
    city: "",
    state: "",
  },
  transaction: {
    id: "",
    subscription_id: "",
    subscription_status: "",
    tem_card: "",
    manager: {},
  },
  setStep: (payload: any) => {},
  setEmployees: (payload: any) => {},
  setPlan: (payload: any) => {},
  setUser: (payload: any) => {},
  setAddress: (payload: any) => {},
  setTransaction: (payload: any) => {},
});

