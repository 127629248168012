const Tags = {
  userInfo: {
    title: 'Checkout - User Info',
    id: 695
  },
  userAddress: {
    title: 'Checkout - User Address',
    id: 696
  },
  userTerms: {
    title: 'Checkout - User Terms',
    id: 697
  },
  userPayment: {
    title: 'Checkout - Payment',
    id: 698
  }
};

export default Tags;
