import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ReactGA from "react-ga";
import { useApi, useCheckout, useCompany } from "../../../@hooks";
import { Alert } from "../../../@components/layout";
import ReCAPTCHA from "react-google-recaptcha";
import { Form, Input } from "../../../@components/form";
import { unMask } from "../../../@helpers/unmask";
import Step from "./Step";
import Tags from "../../../@utils/enums/tags";

interface UserFormProps {
  form?: any;
  loading: boolean;
  business: boolean;
  affiliate?: any;
  onValidate?: any;
}

const User = ({
  form,
  loading,
  business,
  affiliate,
  onValidate,
}: UserFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { getValues, formState } = form;
  const showFormErrors = () => {
    if (
      form.errors.document_number &&
      form.errors.document_number.type === "validate"
    )
      return t("validations.fields.validate.document_number");
    if (form.errors.cnpj && form.errors.cnpj.type === "validate")
      return t("validations.fields.validate.cnpj");
    if (form.errors.birthdate && form.errors.birthdate.type === "validate")
      return t("validations.fields.validate.birthdate");
    if (form.errors.phone && form.errors.phone.type === "validate")
      return t("validations.fields.validate.phone_number");
    if (form.errors.email && form.errors.email.type === "validate")
      return t("validations.fields.validate.email");
    if (
      form.errors.password_confirm &&
      form.errors.password_confirm.type === "isPasswordValid"
    )
      return t("validations.fields.validate.password_confirm");
    if (
      form.errors.password &&
      form.errors.password.type === "isPasswordWithConfirmValid"
    )
      return t("validations.fields.validate.password_confirm");
    return t("validations.fields.required");
  };

  const isDocumentNumberValid = (number: string) => {
    number = number.replace(/\D/g, "");

    if (number.toString().length !== 11 || /^(\d)\1{10}$/.test(number))
      return false;

    let result = true;

    [9, 10].forEach((j) => {
      let sumCpf = 0,
        rest;

      number
        .split(/(?=)/)
        .splice(0, j)
        .forEach((digit, i) => {
          sumCpf += parseInt(digit) * (j + 2 - (i + 1));
        });

      rest = sumCpf % 11;
      rest = rest < 2 ? 0 : 11 - rest;

      if (rest.toString() !== number.substring(j, j + 1)) result = false;
    });

    return result;
  };

  const isCNPJValid = (value: any) => {
    const match = value.toString().match(/\d/g);
    const uniqueValues = Array.from(new Set(match));
    const numbers = Array.isArray(match) ? match.map(Number) : [];

    if (uniqueValues.length === 1) return false;
    if (numbers.length !== 14) return false;

    const items = [...match];
    if (items.length === 1) return false;

    const calc = (x: number) => {
      const slice = numbers.slice(0, x);
      let factor = x - 7;
      let sum = 0;

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor--;
        if (factor < 2) factor = 9;
      }

      const result = 11 - (sum % 11);

      return result > 9 ? 0 : result;
    };

    const digits = numbers.slice(12);

    const digit0 = calc(12);
    if (digit0 !== digits[0]) return false;

    const digit1 = calc(13);
    return digit1 === digits[1];
  };

  const isValidEmail = (email: string) => {
    if (email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) return true;
    return false;
  };

  const isBirthdateValid = (date: string): boolean => {
    if (!date || !getValues("birthdate")) {
      return false;
    }

    const [d, m, y]: any = date.replace("_", "").split("/").map(Number);
    const dateValide: any = Number(new Date().getFullYear() - y);

    const isUp18 = dateValide.toString().match(/^[1-9]?[0-9]{1}$|^100$/) >= 18;
    const validDay = d >= 1 && d <= 31;
    const validMonth = m >= 1 && m <= 12;

    if (isUp18 && validDay && validMonth) return true;
    return false;
  };

  const isPhoneValid = (number: string): boolean => {
    const unmaskedValue = unMask(number, /\(|\)|-|_| /g);
    const uniqueValues = Array.from(new Set(unmaskedValue.split("")));

    if (unmaskedValue.length === 11 && uniqueValues.length > 1) return true;
    return false;
  };

  const isPasswordValid = (password: string) => {
    return password === getValues("password");
  };

  const isPasswordWithConfirmValid = (password: string) => {
    if (getValues("password_confirm") === "" && password) {
      return true;
    }

    return password === getValues("password_confirm");
  };

  useEffect(() => {
    onValidate(formState.isValid);
  }, [formState.isValid, onValidate]);

  return (
    <section className="grid grid-cols-12 gap-6">
      {business && (
        <>
          <section className="col-span-12 sm:col-span-6">
            <Input
              name="company_name"
              validation={{ required: true }}
              form={form}
              disabled={loading}
              label={t("checkout.form.user.fields.company_name")}
              type="text"
              placeholder={t("checkout.form.user.placeholders.type")}
              required
            />
          </section>
          <section className="col-span-12 sm:col-span-6">
            <Input
              name="cnpj"
              validation={{
                required: true,
                validate: isCNPJValid,
              }}
              form={form}
              disabled={loading}
              label={t("checkout.form.user.fields.cnpj")}
              type="tel"
              placeholder={t("checkout.form.user.placeholders.type")}
              mask="99.999.999/9999-99"
              required
            />
          </section>
        </>
      )}
      <section className="col-span-12 sm:col-span-6">
        <Input
          name="name"
          validation={{ required: true }}
          form={form}
          disabled={loading}
          label={t("checkout.form.user.fields.name")}
          type="text"
          placeholder={t("checkout.form.user.placeholders.type")}
          required
        />
      </section>
      <section className="col-span-12 sm:col-span-6">
        <Input
          name="document_number"
          validation={{
            required: true,
            validate: isDocumentNumberValid,
          }}
          form={form}
          disabled={loading}
          label={t("checkout.form.user.fields.cpf")}
          type="tel"
          placeholder={t("checkout.form.user.placeholders.type")}
          mask="999.999.999-99"
          required
        />
      </section>
      <section className="col-span-12 sm:col-span-6">
        <Input
          name="birthdate"
          validation={{
            required: true,
            validate: isBirthdateValid,
          }}
          form={form}
          disabled={loading}
          label={t("checkout.form.user.fields.birthdate")}
          type="tel"
          placeholder={t("checkout.form.user.placeholders.type")}
          mask="99/99/9999"
          required
        />
      </section>
      <section className="col-span-12 sm:col-span-6">
        <Input
          name="phone"
          validation={{
            required: true,
            validate: isPhoneValid,
          }}
          form={form}
          disabled={loading}
          label={t("checkout.form.user.fields.phone")}
          type="tel"
          placeholder={t("checkout.form.user.placeholders.type")}
          mask="(99) 99999-9999"
          required
        />
      </section>
      <section className="col-span-12 sm:col-span-12">
        <Input
          name="email"
          validation={{
            required: true,
            validate: isValidEmail,
          }}
          form={form}
          disabled={loading}
          label={t("checkout.form.user.fields.email")}
          type="email"
          placeholder={t("checkout.form.user.placeholders.type")}
          required
        />
      </section>
      {business && (
        <>
          <section className="col-span-12 sm:col-span-6">
            <Input
              name="password"
              validation={{
                required: true,
                validate: {
                  isPasswordWithConfirmValid,
                },
              }}
              form={form}
              disabled={loading}
              label={t("checkout.form.user.fields.password")}
              type="password"
              placeholder={t("checkout.form.user.placeholders.type")}
              required
            />
          </section>
          <section className="col-span-12 sm:col-span-6">
            <Input
              validation={{
                required: true,
                validate: {
                  isPasswordValid,
                },
              }}
              name="password_confirm"
              form={form}
              disabled={loading}
              label={t("checkout.form.user.fields.password_confirm")}
              type="password"
              placeholder={t("checkout.form.user.placeholders.type")}
              required
            />
          </section>
        </>
      )}
      <section className="col-span-12 sm:col-span-6" hidden>
        <Input
          name="affiliate"
          form={form}
          disabled
          type="text"
          value={affiliate?.slug}
        />
      </section>
      {!loading && form.errors && Object.keys(form.errors).length > 0 && (
        <section className="mt-8 col-span-12 sm:col-span-12">
          <Alert>{showFormErrors()}</Alert>
        </section>
      )}
    </section>
  );
};

interface UserProps {
  basePath: string;
  affiliate?: any;
}
interface FormUserProps {
  name: string;
  document_number: string;
  birthdate: string;
  phone: string;
  email: string;
}

const FormUser = ({ basePath, affiliate }: UserProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, setUser, setStep } = useCheckout();
  const [loading, setLoading] = useState(false);
  const { createContact } = useApi();
  const { company } = useCompany();
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<null | string>(null);

  const isDisabled = useMemo(() => loading || !buttonEnabled || !formValid, [buttonEnabled, formValid, loading]);

  const trackContact = useCallback(async (userInfo: FormUserProps) => {
    try {
      const response: any = await createContact({
        email: userInfo.email,
        firstName: userInfo?.name?.split(' ')?.[0],
        lastName: userInfo?.name?.split(' ')?.[1],
        phone: userInfo.phone,
        tag: Tags.userInfo.id
      });

      setUser({ ...user, contact: response?.contact?.id });
    } catch (err) {
      console.error(err);
    }
  }, [createContact, user, setUser]);

  const track = (): void => {
    const pathName = `/${basePath + history.location.pathname}${
      history.location.search ? history.location.search : ""
    }`;
    ReactGA.set({ page: pathName });
    ReactGA.pageview(pathName);
    console.log("page view", pathName);
  };

  const handleSubmit = async (values: FormUserProps) => {
    setLoading(true);
    setStep(2);
    setUser(values);
    if (company?.operator?.slug === 'tem') {
      await trackContact(values);
    }
    track();
    history.push(`address${affiliate ? `?affiliate=${affiliate.slug}` : ""}`);
    return recaptchaToken;
  };

  const onChange = (value: null | string) => {
    setButtonEnabled(value !== null || value !== '');
    setRecaptchaToken(value);
  };

  const recaptchaRef = useRef<ReCAPTCHA>();

  return (
    <section className="w-full p-6 rounded-3xl my-12 shadow-lg">
      <Step />
      <section className="pt-12 border-t border-gray-100">
        <Form onSubmit={handleSubmit} name="form-checkout-userInfo">
          <User
            business={company?.operator?.business as boolean}
            loading={loading}
            affiliate={affiliate}
            onValidate={setFormValid}
          />

          <div className="flex my-4 justify-center">
            <ReCAPTCHA
              ref={recaptchaRef as React.LegacyRef<ReCAPTCHA>}
              size="compact"
              sitekey={process.env.REACT_APP_GOOGLE_KEY as string}
              onChange={onChange}
            />
          </div>
          <section className="mt-6">
            <button
              id="button-checkout-userInfo"
              type="submit"
              className={`w-full h-11 px-6 bg-primary text-white text-base font-bold rounded-full hover:bg-opacity-70 focus:outline-none ${isDisabled && 'disabled:opacity-70 cursor-not-allowed'}`}
              disabled={isDisabled}
            >
              {t(`buttons.${loading ? "loading" : "confirm"}`)}
            </button>
          </section>
        </Form>
      </section>
    </section>
  );
};

export default FormUser;
