import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useApi, useCheckout, useCompany } from "../../@hooks";
import { Header, Footer, Modal, Alert } from "../../@components/layout";
import { isEmpty } from "../../@helpers/verify";
import { Form, Input } from "../../@components/form";
import Plan from "./components/Plan";
import FormCalculator from "./components/FormCalculator";
import FormUser from "./components/FormUser";
import FormAddress from "./components/FormAddress";
import FormTerms from "./components/FormTerms";
import FormPayment from "./components/FormPayment";
import { Plan as PlanProps } from "../../@contexts";

interface ParamsProps {
  plan: "basic" | "premium" | "super-premium";
  step: string;
}

const Affiliate = ({ form }: any) => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        form={form}
        required
        name="slug"
        type="text"
        placeholder="Digite"
        validation={{
          required: true,
        }}
      />
      {form.errors && Object.keys(form.errors).length > 0 && (
        <section className="mt-8 col-span-12 sm:col-span-12">
          <Alert>{t("validations.fields.required")}</Alert>
        </section>
      )}
    </>
  );
};

export const Checkout = (props: any): JSX.Element => {
  const { t } = useTranslation();
  const { getAffiliate } = useApi();
  const {
    match: {
      params: { plan, step },
    },
  }: RouteComponentProps<ParamsProps> = props;
  const history = useHistory();
  const location = useLocation();
  const { company } = useCompany();
  const { user, setPlan } = useCheckout();
  const planDetails: PlanProps | undefined = company?.plans?.find(
    (item) => item.external_reference === plan
  );
  const params = useMemo(
    () => new URLSearchParams(props.location.search),
    [props.location.search]
  );
  const [loading, setLoading] = useState(true);
  const [affiliate, setAffiliate] = useState<any>(null);
  const [modalState, setModalState] = useState<boolean>(false);

  const formRef: any = useRef(null);

  const submitForm = () =>
    formRef?.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  useEffect(() => {
    if (planDetails) {
      setPlan(planDetails);
    } else {
      history.push('../not-found');
    }
  }, [planDetails, history, setPlan]);

  useEffect(() => {
    if (!loading) {
      if (step === "calculator" && !company?.operator?.business)
        history.push(`user${affiliate ? `?affiliate=${affiliate.slug}` : ""}`);
      if (["address", "terms", "payment"].includes(step) && isEmpty(user))
        history.push(`user${affiliate ? `?affiliate=${affiliate.slug}` : ""}`);
    }
  }, [step, history, user, props.basePath, company, loading, affiliate]);

  const handleAffiliate = useCallback(async () => {
    try {
      await getAffiliate(params.get("affiliate") ?? "")
        .then((data) => {
          setAffiliate(data ?? null);
        })
        .catch((error) => {
          setAffiliate(null);
        });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, [getAffiliate, params]);

  useEffect(() => {
    if (params.get("affiliate")) handleAffiliate();
    else setLoading(false);
  }, [handleAffiliate, params]);

  const renderForm = useCallback(() => {
    const forms = [
      FormCalculator,
      FormUser,
      FormAddress,
      FormTerms,
      FormPayment,
    ];
    const steps = ["calculator", "user", "address", "terms", "payment"];
    const Component: any = forms[steps.indexOf(step)];
    return (
      <Component
        plan={planDetails}
        basePath={props.basePath}
        affiliate={affiliate}
      />
    );
  }, [step, planDetails, affiliate, props.basePath]);

  return (
    <>
      <Header />
      <section className="w-full px-6 sm:px-6">
        <section className="sm:w-144.5 w-full mx-auto">
          <section className="p-6 text-black flex items-center justify-between">
            <p className="text-base">{t("checkout.header.selected_plan")}</p>
            {company?.plan_url && company?.plan_url !== "" && (
              <a href={company?.plan_url} className="text-base underline">
                {t("checkout.header.change_plan")}
              </a>
            )}
          </section>
          {planDetails && (
            <Plan
              plan={planDetails}
              business={company?.operator?.business as boolean}
            />
          )}
          {renderForm()}
        </section>
      </section>
      <Modal
        state={modalState}
        title="Alterar afiliado"
        description="Insira o código do novo afiliado"
        onClick={() => submitForm()}
        onClose={(state: boolean) => setModalState(state)}
      >
        <Form
          formRef={formRef}
          onSubmit={({ slug }: { slug: string }) => {
            setModalState(false);
            history.push(`${location.pathname}?affiliate=${slug}`);
          }}
        >
          <Affiliate />
        </Form>
      </Modal>
      <Footer
        affiliate={affiliate}
        onChange={() => setModalState(true)}
        onRemove={() => {
          setAffiliate(null);
          history.push(location.pathname);
        }}
      />
    </>
  );
};

