import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ReactGA from "react-ga";
import { useCheckout } from "../../../@hooks";
import { FormatAmount } from "../../../@helpers/format";
import { Controller } from "react-hook-form";
import { Form, Input } from "../../../@components/form";

interface CalculatorFormProps {
  form?: any;
  loading: boolean;
}

interface PlanProps {
  amount: number;
}

const Calculator = ({ form, loading }: CalculatorFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { plan, employees } = useCheckout();
  const { control, setValue, watch } = form;
  const f = watch();
  const { amount }: PlanProps = plan;

  const [amountPlan, setAmountPlan] = useState(amount);

  useEffect(() => {
    if (!f.employees) {
      setValue("employees", employees);
    }
  }, [setValue, f.employees, employees]);

  useEffect(() => {
    if (f.employees) {
      setValue("employees", f.employees);
      setAmountPlan(amount * parseInt(f.employees));
    }
  }, [setValue, setAmountPlan, amount, f.employees]); // eslint-disable-next-line

  return (
    <section className="grid grid-cols-12 gap-6">
      <section className="col-span-12 sm:col-span-9">
        <section className="w-full h-full flex items-center">
          <Controller
            control={control}
            name="employees"
            defaultValue="1"
            render={(props) => (
              <input
                {...props}
                className="rounded-lg appearance-none bg-gray-100 h-2 w-full focus:outline-none"
                type="range"
                min={1}
                max={50}
                value={f.employees}
                defaultValue={""}
              />
            )}
          />
        </section>
      </section>
      <section className="col-span-12 sm:col-span-3">
        <Controller
          control={control}
          name="employees"
          rules={{ required: true }}
          defaultValue=""
          render={(props) => (
            <Input
              {...props}
              form={form}
              disabled={loading}
              type="number"
              min={1}
              required
              value={f.employees}
              defaultValue={""}
            />
          )}
        />
      </section>
      <section className="col-span-12 sm:col-span-12">
        <section className="flex items-center justify-between mt-32">
          <p className="text-xl">{t("checkout.form.calculator.text.value")}</p>
          <h2 className="font-semibold text-4xl">
            R$ {FormatAmount(amountPlan)}
          </h2>
        </section>
      </section>
    </section>
  );
};

interface CalculatorProps {
  basePath: string;
}

interface FormCalculatorProps {
  employees: number;
}

const FormUser = ({ basePath }: CalculatorProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setStep, setEmployees } = useCheckout();
  const [loading, setLoading] = useState(false);

  const track = (): void => {
    const pathName = `/${basePath + history.location.pathname}${
      history.location.search ? history.location.search : ""
    }`;
    ReactGA.set({ page: pathName });
    ReactGA.pageview(pathName);
    console.log("page view", pathName);
  };

  const handleSubmit = async (values: FormCalculatorProps) => {
    setLoading(true);
    setEmployees(values.employees);
    setStep(1);
    track();
    history.push("user");
  };

  return (
    <section className="w-full p-6 rounded-3xl my-12 shadow-lg">
      <h3 className="font-semibold text-xl my-3">
        {t("checkout.form.calculator.text.title")}
      </h3>
      <section className="pt-32">
        <Form onSubmit={handleSubmit}>
          <Calculator loading={loading} />
          <section className="mt-12">
            <button
              type="submit"
              className="w-full h-11 px-6 bg-primary text-white text-base font-bold rounded-full hover:bg-opacity-70 focus:outline-none"
              disabled={loading}
            >
              {t(`buttons.${loading ? "loading" : "subscription_now"}`)}
            </button>
          </section>
        </Form>
      </section>
    </section>
  );
};

export default FormUser;
