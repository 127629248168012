import React, { Ref } from "react";
import styled from "styled-components";

import { useForm } from "react-hook-form";

interface FormProps {
  id?: string;
  onSubmit: Function;
  children: JSX.Element | JSX.Element[];
  defaultValues?: object;
  formRef?: Ref<HTMLFormElement>;
  name?: string;
}

const FormContainer = styled.form``;

const Form = ({
  id,
  onSubmit,
  children,
  defaultValues,
  formRef,
  name,
  ...rest
}: FormProps): JSX.Element => {
  const methods = useForm({ defaultValues, mode: "onChange" });

  return (
    <FormContainer
      ref={formRef}
      onSubmit={methods.handleSubmit((data) => onSubmit(data, methods))}
      name={name}
      {...rest}
    >
      {React.Children.map(children, (child) => {
        return typeof child?.type === "function"
          ? React.cloneElement(child, {
              form: methods || {},
              key: child.props.name,
            })
          : child;
      })}
    </FormContainer>
  );
};

export default Form;

