import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCheckout } from '../../../@hooks'

const Step = (): JSX.Element => {
  const { t } = useTranslation()
  const { step } = useCheckout()

  const title = () => {
    const titles = ["user", "address", "terms", "payment"]
    const steps = [1, 2, 3, 4]
    return titles[steps.indexOf(step)]
  }

	return (
    <section className="w-full flex flex-col">
      <small className="text-xs uppercase">
        {t('checkout.form.step.title', { current: step, total: 4 })}
      </small>
      <h3 className="font-semibold text-xl my-3">
        {t(`checkout.form.step.steps.${title()}`)}
      </h3>
    </section>
  )
}

export default Step
