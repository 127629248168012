import React, { useReducer, useCallback } from 'react'
import { CheckoutContext, Plan } from '../@contexts/index'

interface StateProps {
	step: number
	employees: number
	plan: Plan
	user: {
		name: string
		cnpj: string
		company_name: string
		password: string
    document_number: string
    birthdate: string
    phone: string
    email: string
	}
	address: {
		zipcode: string
    address: string
    number: string
    complement: string
    district: string
    city: string
    state: string
	}
	transaction: {
		id: string
		subscription_id: string
		subscription_status: string
		tem_card: string
		manager: any
	}
	setStep?: Function
	setPlan?: Function
	setUser?: Function
	setAddress?: Function
}

interface ActionProps {
	type: string
	payload: object
}

const checkoutReducer = (state: StateProps, action: ActionProps): StateProps => {
	const { type, payload }: any = action

	switch (type) {
		case 'SET_STEP':
			return { ...state, step: payload }
		case 'SET_EMPLOYEES':
			return { ...state, employees: payload }
		case 'SET_PLAN':
			return { ...state, plan: payload }
		case 'SET_USER':
			return { ...state, user: payload }
		case 'SET_ADDRESS':
			return { ...state, address: payload }
		case 'SET_TRANSACTION':
			return { ...state, transaction: payload }
		default:
			throw new Error('Invalid action')
	}
}

interface ProviderProps {
	children: JSX.Element
	step: number
	employees: number
	plan: Plan
	user: {
		name: string
		cnpj: string
		company_name: string
		password: string
    document_number: string
    birthdate: string
    phone: string
    email: string
	}
	address: {
		zipcode: string
    address: string
    number: string
    complement: string
    district: string
    city: string
    state: string
	}
	transaction: {
		id: string
		subscription_id: string
		subscription_status: string
		tem_card: string
		manager: any
	}
}

export const CheckoutProvider = ({
	children,
	step,
	employees,
	plan,
	user,
	address,
	transaction
}: ProviderProps): JSX.Element => {
	const [state, dispatch] = useReducer(
		checkoutReducer, { step, employees, plan, user, address, transaction }
	)

	const setPlan = useCallback((value) => {
		dispatch({
			type: 'SET_PLAN',
			payload: value
		})
	}, [])

	const setStep = useCallback((value) => {
		dispatch({
			type: 'SET_STEP',
			payload: value
		})
	}, [])

	const setEmployees = useCallback((value) => {
		dispatch({
			type: 'SET_EMPLOYEES',
			payload: value
		})
	}, [])

	const setUser = useCallback((value) => {
		dispatch({
			type: 'SET_USER',
			payload: value
		})
	}, [])

	const setAddress = useCallback((value) => {
		dispatch({
			type: 'SET_ADDRESS',
			payload: value
		})
	}, [])

	const setTransaction = useCallback((value) => {
		dispatch({
			type: 'SET_TRANSACTION',
			payload: value
		})
	}, [])

	return (
		<CheckoutContext.Provider
			value={{
				...state,
				setStep,
				setEmployees,
				setPlan,
				setUser,
				setAddress,
				setTransaction
			}}
		>
			{children}
		</CheckoutContext.Provider>
	);
};

export default CheckoutProvider
