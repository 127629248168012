import { isJsonString } from "./verify";

const recordInvalidErrorReplacer = (
  message: string,
  returnedMessage: string,
  errorData: any
) => {
  if (returnedMessage.includes("Invalid Recaptcha.")) {
    return "Ocorreu um erro ao submeter as informaçoes, por favor tente novamente.";
  }
  if (isJsonString(returnedMessage)) {
    const error = JSON.parse(returnedMessage);
    if (
      error?.message?.includes("Plan not found") ||
      error?.messages?.plan_id
    ) {
      return "Plano de assinatura com configurações inválidas.";
    }

    if (error?.message?.includes("Gateway failed in adesion charge")) {
      if (error?.status_detail?.includes("não autorizada")) {
        return "Falha na cobrança da adesão: Transação não autorizada pelo cartão.";
      }
      return "Falha na cobrança da adesão, verifique o saldo e tente novamente. Se o erro persistir, entre em contato com a operadora do seu cartão de crédito.";
    }
    if (error?.message?.includes("Failed in payment generation")) {
      return "Ocorreu um erro ao tentar gerar o boleto, por favor tente novamente.";
    }
  }
  if (returnedMessage.includes("This user already have an active card")) {
    return "Este usuário já possui um cartão ativo para esse produto.";
  }
  if (isJsonString(errorData?.additional_info)) {
    const error = JSON.parse(errorData.additional_info);
    if (error?.errors?.card) {
      if (error?.errors?.card[0].includes("card number")) {
        return "Número de cartão de crédito inválido.";
      }
    }
  }
  return defaultErrorReplacer(message, returnedMessage, errorData);
};

const defaultErrorReplacer = (
  message: string,
  returnedMessage: string,
  _errorData: any
) => {
  return message.replace(
    "<replace>",
    returnedMessage?.substr(returnedMessage.indexOf(":") + 1) || ""
  );
};

const internalServerErrorReplacer = (
  message: string,
  returnedMessage: string,
  errorData: any
) => {
  if (returnedMessage.includes("Timed out"))
    return message.replace("<replace>", "Tempo de resposta esgotado.");
  if (errorData?.additional_info?.includes("card verification failed"))
    return "Não foi possivel verificar o cartão de crédito, verifique a validade e código de segurança.";
  if (returnedMessage.includes("Invalid Plan Configuration"))
    return message.replace(
      "<replace>",
      "Este plano não possui ID de plano vinculado."
    );

  if (returnedMessage.includes("500 Internal Server Error"))
    return message.replace(
      "<replace>",
      "Gateway falhou em executar a requisição, por favor tente novamente."
    );
  return message.replace("<replace>", "não mapeado");
};

const errorDict: {
  [key: string]: (returnedMessage: string, errorData: any) => string;
} = {
  parameter_missing: (returnedMessage: string, errorData: any) =>
    defaultErrorReplacer(
      "Parâmetro faltando: <replace>",
      returnedMessage,
      errorData
    ),
  internal_server_error: (returnedMessage: string, errorData: any) =>
    internalServerErrorReplacer(
      "Erro interno: <replace>",
      returnedMessage,
      errorData
    ),
  record_invalid: (returnedMessage: string, errorData: any) =>
    recordInvalidErrorReplacer(
      "Parametros Inválidos: <replace>",
      returnedMessage,
      errorData
    ),
  default: (returnedMessage: string, errorData: any) =>
    defaultErrorReplacer("Erro interno: <replace>", returnedMessage, errorData),
};

const errorFormater = (error: any) => {
  if (error?.response?.data) {
    const errorType = error.response.data?.error || "default";
    const errorRenderer = errorDict[errorType] || errorDict.default;
    const returnedMessage = error.response.data.message;
    return errorRenderer(returnedMessage, error.response.data);
  }
  return "Erro não mapeado";
};

export default errorFormater;

